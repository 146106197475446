<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ANNUAL EXPENDITURE MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="year"
              class="mx-2"
              dense
              outlined
              label="Year Of"
              :items="month_of_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="save_request"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Current','Non Current']"
              :rules="rules.combobox_rule"
              @change="save_request"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-form>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="['Liquidated','UnLiquidated (REG)','UnLiquidated (CA)']"
                    disable-pagination
                    hide-default-footer
      >

        <template v-slot:top>
          <h2 v-if="!saving_data" class="mx-2">PRINT
            <v-icon
              class="mr-2"
              color="success"
              @click="print_data"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </h2>

          <v-progress-circular
            :size=50
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{item}}
            </td>
            <td>
              <v-data-table dense
                            class="mt-3"
                            :headers="headers2"
                            :items="item==='Liquidated'?data_items.liquidated:(item==='UnLiquidated (REG)'?data_items.unliquidated_reg:(item==='UnLiquidated (CA)'?data_items.unliquidated_ca:[]))"
                            disable-pagination
                            hide-default-footer
              >
                <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{item.category }}
                      </td>
                      <td>
                        {{item.jan===0?'': formatPrice(item.jan) }}
                      </td>
                      <td>
                        {{item.feb===0?'': formatPrice(item.feb) }}
                      </td>
                      <td>
                        {{item.mar===0?'': formatPrice(item.mar) }}
                      </td>
                      <td>
                        {{item.apr===0?'': formatPrice(item.apr) }}
                      </td>
                      <td>
                        {{item.may===0?'': formatPrice(item.may) }}
                      </td>
                      <td>
                        {{item.jun===0?'': formatPrice(item.jun) }}
                      </td>
                      <td>
                        {{item.jul===0?'': formatPrice(item.jul) }}
                      </td>
                      <td>
                        {{item.aug===0?'': formatPrice(item.aug) }}
                      </td>
                      <td>
                        {{item.sep===0?'': formatPrice(item.sep) }}
                      </td>
                      <td>
                        {{item.oct===0?'': formatPrice(item.oct) }}
                      </td>
                      <td>
                        {{item.nov===0?'': formatPrice(item.nov) }}
                      </td>
                      <td>
                        {{item.dec===0?'': formatPrice(item.dec) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
            </td>
          </tr>
        </template>
      </v-data-table>

    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      is_done: 0,
      option_items: [],
      category_items: [],
      month_of_items: [],
      particulars_items: [],
      branch_items: [],
      data_items: [],
      jan_data_items: [],
      feb_data_items: [],
      mar_data_items: [],
      apr_data_items: [],
      may_data_items: [],
      jun_data_items: [],
      jul_data_items: [],
      aug_data_items: [],
      sep_data_items: [],
      oct_data_items: [],
      nov_data_items: [],
      dec_data_items: [],
      category_id: '',
      branch_id: '',
      option: '',
      particulars_id: '',
      alert_message: '',
      selection: '',
      year: '',
      total_amount: '0.00',
      headers: [
        {text: 'Status', value: 'date_of_deposit', sortable: false},
        {text: '', value: 'date_of_deposit', sortable: false},
      ],
      headers2: [
        {text: 'Category', value: 'date_of_deposit', sortable: false},
        {text: 'Jan.', value: 'date_of_deposit', sortable: false},
        {text: 'Feb.', value: 'date_of_deposit', sortable: false},
        {text: 'Mar.', value: 'date_of_deposit', sortable: false},
        {text: 'Apr.', value: 'date_of_deposit', sortable: false},
        {text: 'May', value: 'date_of_deposit', sortable: false},
        {text: 'Jun.', value: 'date_of_deposit', sortable: false},
        {text: 'Jul.', value: 'date_of_deposit', sortable: false},
        {text: 'Aug.', value: 'date_of_deposit', sortable: false},
        {text: 'Sep.', value: 'date_of_deposit', sortable: false},
        {text: 'Oct.', value: 'date_of_deposit', sortable: false},
        {text: 'Nov.', value: 'date_of_deposit', sortable: false},
        {text: 'Dec.', value: 'date_of_deposit', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    watch: {
      is_done: function () {
        if (this.is_done === 0) {
          this.annual_expenditure_monitoring({
            year: this.year,
            is_current: this.selection==='Current',
            jan_data_items: JSON.stringify(this.jan_data_items),
            feb_data_items: JSON.stringify(this.feb_data_items),
            mar_data_items: JSON.stringify(this.mar_data_items),
            apr_data_items: JSON.stringify(this.apr_data_items),
            may_data_items: JSON.stringify(this.may_data_items),
            jun_data_items: JSON.stringify(this.jun_data_items),
            jul_data_items: JSON.stringify(this.jul_data_items),
            aug_data_items: JSON.stringify(this.aug_data_items),
            sep_data_items: JSON.stringify(this.sep_data_items),
            oct_data_items: JSON.stringify(this.oct_data_items),
            nov_data_items: JSON.stringify(this.nov_data_items),
            dec_data_items: JSON.stringify(this.dec_data_items),
          })
            .then(response => {
              this.data_items = response.data[0]
              this.saving_data = false
              this.alert = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('monthly_liquidation', ['annual_expenditure_monitoring','monthly_expenditure']),
      ...mapActions('transaction_months', ['list_of_transaction_month', 'search_transaction_month', 'search_transaction_year']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      search_by_month(month) {
        this.search_transaction_month({
          month_of: month + ' ' + this.year,
        })
          .then(response => {
            if (Object.keys(response.data).length > 0) {
              this.monthly_expenditure({
                month_of_id: response.data.id,
                month_of: response.data.month_of,
                category_id: -1,
                particulars_id: '',
                branch_id: '',
                is_current: this.selection === 'Current' ? 1 : 0,
                is_branch: 0,
                is_summary_particulars: 0,
                is_region: 0,
                is_territory: 0,
              })
                .then(response => {
                  switch (month) {
                    case 'January':
                      this.jan_data_items = response.data
                      break
                    case 'February':
                      this.feb_data_items = response.data
                      break
                    case 'March':
                      this.mar_data_items = response.data
                      break
                    case 'April':
                      this.apr_data_items = response.data
                      break
                    case 'May':
                      this.may_data_items = response.data
                      break
                    case 'June':
                      this.jun_data_items = response.data
                      break
                    case 'July':
                      this.jul_data_items = response.data
                      break
                    case 'August':
                      this.aug_data_items = response.data
                      break
                    case 'September':
                      this.sep_data_items = response.data
                      break
                    case 'October':
                      this.oct_data_items = response.data
                      break
                    case 'November':
                      this.nov_data_items = response.data
                      break
                    case 'December':
                      this.dec_data_items = response.data
                      break
                  }
                  this.alert_message = 'GETTING DATA OF ' + month + ' ' + this.year + '...'
                  this.is_done--
                })
                .catch(error => {
                  console.log(error)
                });
            }
          })
          .catch(error => {
            console.log(error)
          });
      },
      save_request() {
        this.data_items = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        if (this.$refs.form.validate()) {
          this.search_transaction_year({
            year: this.year
          })
            .then(response => {
              this.is_done = response.data.length
            })
            .catch(error => {
              console.log(error)
            })
          this.search_by_month('January')
          this.search_by_month('February')
          this.search_by_month('March')
          this.search_by_month('April')
          this.search_by_month('May')
          this.search_by_month('June')
          this.search_by_month('July')
          this.search_by_month('August')
          this.search_by_month('September')
          this.search_by_month('October')
          this.search_by_month('November')
          this.search_by_month('December')
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async selected_category() {
        this.data_items = []
        this.particulars_id = ''
        this.branch_id = ''
        this.option = ''
        await this.save_request()
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var payments_array2 = []
          var payments_array3 = []
          var payments_array4 = []
          var widths = []
          var payment = 0
          var info_array = []

          widths = [80,80,50,50,50,50,50,50,50,50,50,50,50,50]
          payments_array.push([
            {text: 'Status', alignment: 'left', style: 'main_info',},
            {text: 'Category', alignment: 'left', style: 'main_info',},
            {text: 'Jan.', alignment: 'left', style: 'main_info'},
            {text: 'Feb.', alignment: 'left', style: 'main_info'},
            {text: 'Mar.', alignment: 'left', style: 'main_info'},
            {text: 'Apr.', alignment: 'left', style: 'main_info'},
            {text: 'May', alignment: 'left', style: 'main_info'},
            {text: 'Jun.', alignment: 'left', style: 'main_info'},
            {text: 'Jul.', alignment: 'left', style: 'main_info'},
            {text: 'Aug.', alignment: 'left', style: 'main_info'},
            {text: 'Sep.', alignment: 'left', style: 'main_info'},
            {text: 'Oct.', alignment: 'left', style: 'main_info'},
            {text: 'Nov.', alignment: 'left', style: 'main_info'},
            {text: 'Dec.', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.liquidated.forEach(function (item) {
            payments_array.push([
              {
                text: item.id>0?'Liquidated':'',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.category,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jan === 0 ? '' : (item.jan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.feb === 0 ? '' : (item.feb / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.mar === 0 ? '' : (item.mar / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.apr === 0 ? '' : (item.apr / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.may === 0 ? '' : (item.may / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jun === 0 ? '' : (item.jun / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jul === 0 ? '' : (item.jul / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.aug === 0 ? '' : (item.aug / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.sep === 0 ? '' : (item.sep / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.oct === 0 ? '' : (item.oct / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.nov === 0 ? '' : (item.nov / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.dec === 0 ? '' : (item.dec / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
          payments_array.push([
            {text: '', alignment: 'left', style: 'main_info',},
            {text: '', alignment: 'left', style: 'main_info',},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.unliquidated_reg.forEach(function (item) {
            payments_array.push([
              {
                text: item.id>0?'UnLiquidated (REG)':'',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.category,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jan === 0 ? '' : (item.jan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.feb === 0 ? '' : (item.feb / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.mar === 0 ? '' : (item.mar / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.apr === 0 ? '' : (item.apr / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.may === 0 ? '' : (item.may / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jun === 0 ? '' : (item.jun / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jul === 0 ? '' : (item.jul / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.aug === 0 ? '' : (item.aug / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.sep === 0 ? '' : (item.sep / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.oct === 0 ? '' : (item.oct / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.nov === 0 ? '' : (item.nov / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.dec === 0 ? '' : (item.dec / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
          payments_array.push([
            {text: '', alignment: 'left', style: 'main_info',},
            {text: '', alignment: 'left', style: 'main_info',},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
            {text: '', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.unliquidated_ca.forEach(function (item) {
            payments_array.push([
              {
                text: item.id>0?'UnLiquidated (CA)':'',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.category,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jan === 0 ? '' : (item.jan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.feb === 0 ? '' : (item.feb / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.mar === 0 ? '' : (item.mar / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.apr === 0 ? '' : (item.apr / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.may === 0 ? '' : (item.may / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jun === 0 ? '' : (item.jun / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jul === 0 ? '' : (item.jul / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.aug === 0 ? '' : (item.aug / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.sep === 0 ? '' : (item.sep / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.oct === 0 ? '' : (item.oct / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.nov === 0 ? '' : (item.nov / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.dec === 0 ? '' : (item.dec / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    {
                      text: this.selection,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Year of: ',
                    {
                      text: this.year,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'landscape',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'ANNUAL EXPENDITURE MONITORING',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
